<template>
  <div class="popup_wrap" style="width:700px;">
    <button type="button" class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('msg.REPORT_PDF.114') }}<!-- 이메일 공유 --></h1>
      <div class="content_box">
        <form id="pdfMailForm">
          <table class="tbl_row">
            <colgroup>
              <col width="120"><col>
            </colgroup>
            <tbody>
              <tr>
                <th>{{ $t('msg.REPORT_PDF.106') }}<!-- 제목 --></th>
                <td>
                  <input type="text" id="subject" v-model="regParams.subject" maxlength="200" @keyup="checkValidation('subject')">
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.REPORT_PDF.104') }}<!-- 발송 --></th>
                <td>
                  <input type="text" id="fromEmail" v-model="regParams.fromEmail" @keyup="checkValidation('fromEmail')">
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.REPORT_PDF.105') }}<!-- 수취 --></th>
                <td>
                  <input type="text" id="toEmail" v-model="regParams.toEmail" @keyup="checkValidation('toEmail')" :placeholder="$t('msg.REPORT_PDF.113')">
                </td>
              </tr>
              <tr>
                <th class="valign_top">{{ $t('msg.REPORT_PDF.107') }}<!-- 내용 --></th>
                <td>
                  <textarea id="message" v-model="regParams.message" style="height:180px;" @keyup="checkValidation('message')"></textarea>
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.REPORT_PDF.116') }}<!-- 첨부파일 --></th>
                <td>
                  {{ emailFilesStr }}
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
      <div class="mt10 text_center">
        <a class="button blue lg" href="#" @click.prevent="sendEmail();">{{ $t('msg.REPORT_PDF.108') }}<!-- 보내기 --></a>
        <a class="button gray lg" href="#" @click.prevent="$emit('close');">{{ $t('msg.REPORT_PDF.109') }}<!-- 취소 --></a>
      </div>
    </div>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import commonPdf from '@/plugins/commonPdf'
import auth from '@/api/services/auth'

export default {
  name: 'PdfSendEmailPop',
  components: {},
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      emailUrl: '',
      emailFiles: [],
      emailFilesStr: '',
      regParams: {
        fromEmail: '',
        toEmail: '',
        subject: '',
        message: ''
      }
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    parentInfo () {
      this.emailUrl = this.$props.parentInfo.emailUrl
      this.emailFiles = this.$props.parentInfo.emailFiles
      if (this.emailFiles !== undefined) {
        this.emailFilesStr = this.emailFiles.join(', ')
      }
    }
  },
  created () {
    this.init()
    this.emailUrl = this.$props.parentInfo.emailUrl
    this.emailFiles = this.$props.parentInfo.emailFiles
    if (this.emailFiles !== undefined) {
      this.emailFilesStr = this.emailFiles.join(', ')
    }
  },
  methods: {
    async init () {
      await Promise.all([
        auth.info(this.auth.jwt).then(res => {
          const result = res.data
          this.regParams.fromEmail = result.userEmail || ''
        })
      ])

      this.regParams.sndDocNo = this.$props.parentInfo.sndDocNo || '000000001'
      this.regParams.sndDocKndCd = this.$props.parentInfo.sndDocKndCd || ''
      this.regParams.sndCtrCd = this.$props.parentInfo.sndCtrCd || ''
      this.regParams.sndPlcCd = this.$props.parentInfo.sndPlcCd || ''
      this.regParams.sndUserNo = this.$props.parentInfo.sndUserNo || ''
    },
    sendEmail () {
      if (!this.checkValidationAll()) {
        return
      }

      commonPdf.sendEmail(this, this.emailUrl, this.regParams)
    },
    checkValidationAll () {
      const arrId = ['fromEmail', 'toEmail', 'subject', 'message']
      let isOk = true

      arrId.forEach(id => {
        if (!this.checkValidation(id)) {
          isOk = false
        }
      })

      if (!this.checkValidationEmail('toEmail')) {
        isOk = false
      }

      return isOk
    },
    checkValidation (id) {
      const frm = document.querySelector('#pdfMailForm')
      const selector = frm.querySelector('#' + id)
      let isOk = true

      this.$ekmtcCommon.hideErrorTooltip(selector)

      switch (id) {
        case 'fromEmail':
          if (this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.REPORT_PDF.110'))
            isOk = false
          } else if (!this.$ekmtcCommon.checkEmail(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.REPORT_PDF.111'))
            isOk = false
          }
          break
        case 'toEmail':
          if (this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.REPORT_PDF.110'))
            isOk = false
          }
          break
        case 'subject':
          if (this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.REPORT_PDF.110'))
            isOk = false
          }
          break
        case 'message':
          if (this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.REPORT_PDF.110'))
            isOk = false
          }
          break
      }
      return isOk
    },
    checkValidationEmail (id) {
      const frm = document.querySelector('#pdfMailForm')
      const selector = frm.querySelector('#' + id)
      let res
      let isOk = true

      switch (id) {
        case 'toEmail':
          res = this.checkEmail(selector.value)
          if (res !== '') {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.REPORT_PDF.112', { errEmail: res }))
            isOk = false
          }
          break
      }
      return isOk
    },
    checkEmail (str) {
      if (str === '') {
        return ''
      }

      const arrEmail = []
      const arr = str.split(',')
      let arr2

      for (let i = 0; i < arr.length; i++) {
        if (arr[i].indexOf(';') > -1) {
          arr2 = arr[i].split(';')
          for (let j = 0; j < arr2.length; j++) {
            arrEmail.push(arr2[j])
          }
        } else {
          arrEmail.push(arr[i])
        }
      }

      if (arrEmail.length === 0) {
        return ''
      }

      const errEmail = []

      for (let i = 0; i < arrEmail.length; i++) {
        if (!this.$ekmtcCommon.checkEmail(arrEmail[i])) {
          errEmail.push(arrEmail[i])
        }
      }
      return errEmail.join(', ')
    }
  }
}
</script>
