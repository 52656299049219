var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "700px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.REPORT_PDF.114"))),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("form", { attrs: { id: "pdfMailForm" } }, [
            _c("table", { staticClass: "tbl_row" }, [
              _vm._m(0),
              _c("tbody", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.REPORT_PDF.106")))]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regParams.subject,
                          expression: "regParams.subject",
                        },
                      ],
                      attrs: { type: "text", id: "subject", maxlength: "200" },
                      domProps: { value: _vm.regParams.subject },
                      on: {
                        keyup: function ($event) {
                          return _vm.checkValidation("subject")
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.regParams,
                            "subject",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.REPORT_PDF.104")))]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regParams.fromEmail,
                          expression: "regParams.fromEmail",
                        },
                      ],
                      attrs: { type: "text", id: "fromEmail" },
                      domProps: { value: _vm.regParams.fromEmail },
                      on: {
                        keyup: function ($event) {
                          return _vm.checkValidation("fromEmail")
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.regParams,
                            "fromEmail",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.REPORT_PDF.105")))]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regParams.toEmail,
                          expression: "regParams.toEmail",
                        },
                      ],
                      attrs: {
                        type: "text",
                        id: "toEmail",
                        placeholder: _vm.$t("msg.REPORT_PDF.113"),
                      },
                      domProps: { value: _vm.regParams.toEmail },
                      on: {
                        keyup: function ($event) {
                          return _vm.checkValidation("toEmail")
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.regParams,
                            "toEmail",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("tr", [
                  _c("th", { staticClass: "valign_top" }, [
                    _vm._v(_vm._s(_vm.$t("msg.REPORT_PDF.107"))),
                  ]),
                  _c("td", [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regParams.message,
                          expression: "regParams.message",
                        },
                      ],
                      staticStyle: { height: "180px" },
                      attrs: { id: "message" },
                      domProps: { value: _vm.regParams.message },
                      on: {
                        keyup: function ($event) {
                          return _vm.checkValidation("message")
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.regParams,
                            "message",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.REPORT_PDF.116")))]),
                  _c("td", [_vm._v(" " + _vm._s(_vm.emailFilesStr) + " ")]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.sendEmail()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.REPORT_PDF.108")))]
          ),
          _c(
            "a",
            {
              staticClass: "button gray lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.REPORT_PDF.109")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "120" } }), _c("col")])
  },
]
render._withStripped = true

export { render, staticRenderFns }